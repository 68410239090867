import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import Footer from "../components/footer";
import Header from "../components/header";

const PrivacyWrapper = styled.div`
   background-color: white;
   margin: 0;
   overflow: hidden;
   position: relative;
`

const PrivacyInner = styled.div`
   max-width: 1060px; 
   margin-left: auto;
   margin-right: auto;
   padding: 180px;

   @media (max-width: 768px) {
     max-width: 100%;
     padding: 0 36px 0px 36px;
     margin-top: 86px;
  }
 `

const Privacy = ({ data }) => {
  const {allMarkdownRemark: {edges: posts}} = data;
  const html = posts[0].node.html;
  return (
    <PrivacyWrapper>
      <Header />

      <PrivacyInner>
        <h2>Privacy policy</h2>

        <div dangerouslySetInnerHTML={{ __html: html }}>

        </div>
      </PrivacyInner>

      <Footer />
    </PrivacyWrapper>
  )

}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark {
    edges {
      node {
        html
        excerpt(format: PLAIN)
        tableOfContents
      }
    }
  }
  }
    `

export default Privacy;